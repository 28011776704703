import "./App.css";
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/main/Root"
import Waitlist from "./components/main/Waitlist"
import About from "./components/main/About";
import Notfound from "./components/main/Notfound";
import Modal from "./components/scalables/Modal";

const App = () => {
  const [mailModal, setMailModal] = useState(false);


  return (
    <div className="App">
      <Modal {...{ mailModal, setMailModal }} />
      <Routes>
        <Route
          exac
          path="/waitlist"
          element={<Waitlist />}
        />
        <Route exact path="/" element={<Home {...{ mailModal, setMailModal }} />} />ist
        <Route exact path="/about" element={<About  {...{ mailModal, setMailModal }} />} />
        <Route exact path="*" element={<Notfound />} />
      </Routes>
    </div>
  );
};



export default App;