
import React, { useState } from 'react';
import logo from './../../images/kowelogo.png';
import wallet from './../../images/Wallet.png';
import 'bootstrap/dist/css/bootstrap.css';
import { ToastContainer, toast } from 'react-toastify';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-toastify/dist/ReactToastify.css';
import '@fontsource/manrope';
import './../../App.css';


const Waitlist = () => {

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false); 

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (email) {
      setIsLoading(true); 

      setEmail((prevEmails) => [...prevEmails, email]); 

      try {
        const response = await fetch(
          'https://kowebackend.com/kowepay/api/v1/auth/waitlist',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
          }
        );

        const data = await response.json();

        if (data.status) {
          toast.success(`${data.msg}`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        } else {
          toast.error(`${data.msg}`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }

      setIsLoading(false); // Stop loading

      setEmail(''); // Clear the email input field
    }
  };
  return (
    <div>
     <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
      <nav className="nav">
        <img src={logo} alt="logo" style={{ width: '100px', height: 'auto' }} />
        <p className="link">About us</p>
      </nav>

      <section className="Hero">
        <h1>More spending, less cash or card</h1>
        <div className="textContainer">
          <p>Unlock the next level of convenience with Kowe Pay. Pay in Style, Pay with</p>
          <p className="text2">Ease, Pay with Confidence. Join the Future of Payments Today!</p>
        </div>

        <form id="waitlistForm" onSubmit={handleSubmit}>
        <nav className="emailHero">
        <div className="left-email">
        <input name='email' type="email" id="emailInput" required placeholder="Enter e-mail address" value={email} onChange={handleEmailChange} />
        </div>
        <div className="right-button">
        <div className="btn btn-primary btn-sm custom-btn">
        <button type="submit" className="btn btn-primary btn-sm">
        {isLoading ? (
        <i className="fa fa-spinner fa-spin" />
        ) : (
          'Get Early Access'
        )}
        </button>
        </div>
        </div>
        </nav>
        </form>
      </section>

      <div className="Wallet">
      <img src={wallet} alt="logo" className="center-img" />
      </div>

      <footer className="footer">
        <p className="text-right">Copyright © 2023 Kowe, Inc.</p>
      </footer>
    </div>
  )
}

export default Waitlist