import React, { useLayoutEffect } from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
const axios = require('axios');

const faqs = [
  {
    question: "How do i use the Kowe Wallet?",
    answer:
      "Mainly, 3-steps. Signup, Add Cards and Seamlessly make payments safe and secure using Contactless",
  },
  {
    question: "How safe is Kowe Wallet?",
    answer:
      "Kowe Wallet is processed 10000+ successful payment transactions, without any glitch",
  },
  {
    question: "How do i set up Kowe Wallet?",
    answer:
      "Download and Sign Up, Verify Identity, Add Card, Make Payments",
  },
  {
    question: "What type of cards are supported?",
    answer:
      "Kowe has a large coverage for many cards mostly mastercards, visa, american express etc",
  },
  {
    question: "What is a Digital Wallet?",
    answer:
      "A “digital card” is a copy of your physical bank card that's stored on your phone. Digital cards have the same card number, expiry date, and CVC as your physical bank card, and can be used with Apple Pay or Google Pay. A “disposable card” is a virtual card that can only be used once.",
  },
  {
    question: "What are Contactless Payment?",
    answer:
      "Contactless Payment allows customers to make payments, without using cash or swiping the card. The customers must 'Tap' or 'Wave' their card over a card-reader to use this method. After that, the terminal will connect with the Bank Account and the payment will be made, quickly.",
  },
  {
    question: "Where can i download kowe Wallet?",
    answer:
      "Download and Install on Google Play-Store or the Apple Store ",
  },


]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}







const Faqs = () => {

  const options = {
    method: 'GET',
    url: 'https://api.intercom.io/articles',
    headers: { accept: 'application/json', 'Intercom-Version': '2.9' }
  };

  useLayoutEffect(() => {
    // axios
    //   .request(options)
    //   .then(function (response) {
    //     console.log(response.data);
    //   })
    //   .catch(function (error) {
    //     console.error(error);
    //   });

  }, [])

  return (
    <div>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-32 px-4  sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
            <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">Have Questions ?</h2>
            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                          <span className="font-medium text-gray-900">{faq.question}</span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-gray-500">{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faqs