import React from 'react'
import Navbar from '../scalables/Navbar'
import Footer from '../scalables/Footer'
import Faqs from '../scalables/Faqs'


const Root = ({ mailModal, setMailModal }) => {
  return (
    <>
      <Navbar {...{ mailModal, setMailModal }} />

      {/* Section 1 */}
      <section>
        <div className="section-one">
          <div className="">
            <div className="px-4 pt-24 pb-2 sm:px-6 lg:px-8">
              <h1 className="text-center text-4xl font-extrabold py-3 sm:text-5xl lg:text-6xl">
                <span className="block text-6xl text-black font-normal">Pay with Ease, Pay with </span>
                <span className=" block text-6xl text-black font-normal">Confidence</span>
              </h1>
              <p>
                <span className=' text-center block text-base text-gray-500'>
                  Replace your card and cash with Kowe Wallet for faster
                </span>
                <span className=' text-center block text-base text-gray-500'>
                  payment online and in-store.
                </span>
              </p>
              <div className="my-3 text-center justify-center items-center flex">
                <button
                  type="button"
                  className='px-2'
                  onClick={() => setMailModal(true)}
                >
                  <img
                    className="block h-12 w-auto"
                    src="https://ik.imagekit.io/kowe/kowe/Download%20button.png?updatedAt=1698673288492"
                    alt="kowe"
                  />
                </button>

                <button
                  type="button"
                  className='px-2'
                  onClick={() => setMailModal(true)}
                >
                  <img
                    className="block h-12 w-auto"
                    src="https://ik.imagekit.io/kowe/kowe/Download%20button%20(1).png?updatedAt=1698673288122"
                    alt="kowe"
                  />
                </button>

              </div>
            </div>
          </div>
          <div className="">
            <p className='max-w-5xl mx-auto py-6 px-4 sm:px-6 lg:px-8'>
              <img
                className=" w-full object-cover"
                src="https://ik.imagekit.io/kowe/kowe/Illustration.png?updatedAt=1698672346511"
                alt="Kowe"
              />
            </p>
          </div>
        </div>
      </section>

      {/* Section 2 */}

      <section>
        <main className="my-8 mx-auto py-6 max-w-7xl px-10 sm:mt-24 sm:px-6 lg:mt-32">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8 p-10 ">
            <div className=" flex items-end md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <div>
                <h1>
                  <span className="mt-3 text-3xl tracking-tight font-bold sm:text-5xl">
                    <span className="text-gray-900">Setup your <br /> Wallet in </span>
                    <span className=" text-blue-500">Seconds</span>
                  </span>
                </h1>
              </div>
            </div>
            <div className="mt-1 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <span className="mt-2 block text-xl  font-lg">
                <span className="block text-gray-500">We make in-store and online payments easier than swiping your card or paying with cash</span>
              </span>
            </div>
          </div>
        </main>
      </section>

      {/* Section 3 */}

      <section>
        <main className="my-8 mx-auto py-6 max-w-7xl px-10 sm:mt-24 sm:px-6 lg:mt-32 bg-gray-100 rounded-3xl">
          <span className="block py-4 text-sm font-semibold uppercase tracking-wide text-black sm:text-base lg:text-sm xl:text-base">
            Step 1
          </span>
          <div className="lg:grid lg:grid-cols-12 lg:gap-8 py-10 ">
            <div className=" flex items-end md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <div>
                <h1>
                  <span className="mt-3 block text-3xl tracking-tight font-extrabold sm:text-5xl">
                    <span className="text-gray-900">Download & Sign Up</span>
                    <span className="text-gray-500 px-2">on the Kowe Wallet</span>
                  </span>
                </h1>


              </div>
            </div>
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <div className="relative mx-auto w-full  lg:max-w-md">
                <img
                  className="w-full"
                  src="https://ik.imagekit.io/kowe/kowe/Thumbnail%20(2).png?updatedAt=1698682717967"
                  alt="kowe"
                />
              </div>
            </div>
          </div>
        </main>
      </section>

      {/* Section 4 */}
      <section>
        <main className="my-8 mx-auto py-6 max-w-7xl px-10 sm:mt-24 sm:px-6 lg:mt-32 bg-gray-100 rounded-3xl">
          <span className="block py-4 text-sm font-semibold uppercase tracking-wide text-black sm:text-base lg:text-sm xl:text-base">
            Step 2
          </span>
          <div className="lg:grid lg:grid-cols-12 lg:gap-8 py-10 ">
            <div className=" flex items-end md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <div>
                <h1>
                  <span className="mt-3 block text-3xl tracking-tight font-extrabold sm:text-5xl">
                    <span className="text-gray-900">Add your credit or debit cards</span>
                    <span className="text-gray-500"> to the Wallet app on your smartphone</span>
                  </span>
                </h1>
              </div>
            </div>
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <div className="relative mx-auto w-full  lg:max-w-md">
                <img
                  className="w-full"
                  src="https://ik.imagekit.io/kowe/kowe/Thumbnail%20(3).png?updatedAt=1698682721248"
                  alt="kowe"
                />
              </div>
            </div>
          </div>
        </main>
      </section>

      {/* Section 5 */}

      <section>
        <main className="my-8 mx-auto py-6 max-w-7xl px-10 sm:mt-24 sm:px-6 lg:my-32 bg-gray-100 rounded-3xl">
          <span className="block py-4 text-sm font-semibold uppercase tracking-wide text-black sm:text-base lg:text-sm xl:text-base">
            Step 3
          </span>
          <div className="lg:grid lg:grid-cols-12 lg:gap-8 py-10 ">
            <div className=" flex items-end md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <div>
                <h1>
                  <span className="mt-3 block text-3xl tracking-tight font-extrabold sm:text-5xl">
                    <span className="block text-gray-900">Tap. Pay. Go! </span>
                    <span className="block text-gray-500">Start making payments as you go.</span>
                  </span>
                </h1>


              </div>
            </div>
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <div className="relative mx-auto w-full  lg:max-w-md">
                <img
                  className="w-full"
                  src="https://ik.imagekit.io/kowe/kowe/frfefef%201.png?updatedAt=1698682722133"
                  alt="kowe"
                />
              </div>
            </div>
          </div>
        </main>
      </section>

      {/* Section 6 */}
      <section>
        <div className="bg-black py-8">
          <div className=" max-w-7xl py-16 mx-auto px-4 sm:px-6  items-center lg:px-8 lg:grid lg:grid-cols-2">
            <div className="flex justify-center items-center">
              <img
                src="https://ik.imagekit.io/kowe/kowe/thumbnail%20(1).png?updatedAt=1698680542066"
                alt="kowe"
                className=" w-2/4 h-1/2 object-center object-cover"
              />
            </div>
            <div className="bg-black">
              <div className="max-w-2xl mx-auto py-4">
                <h1 className='block text-6xl text-white py-2 font-normal'>Why use Kowe</h1>
                <div className="py-2">
                  <h1 className='text-blue-500 text-3xl'>
                    Faster
                  </h1>
                  <p className='text-gray-500 text-base'>
                    Effortlessly manage transactions faster and enjoy contactless payments without physical cards or cash — all from your mobile device.
                  </p>

                </div>
                <div className="py-2">
                  <h1 className='text-blue-500 text-3xl'>
                    Wide Coverage
                  </h1>
                  <p className='text-gray-500 text-base'>
                    Kowe is accepted across POS terminals and numerous stores throughout Nigeria, giving you the freedom to transact effortlessly wherever you go.
                  </p>

                </div>
                <div className="py-2">
                  <h1 className='text-blue-500 text-3xl'>
                    Secure & Private
                  </h1>
                  <p className='text-gray-500 text-base'>
                    Your transaction and payment information are exclusively accessible to you and encrypted, ensuring protection from third-party access.
                  </p>

                </div>
              </div>
            </div>

          </div>
        </div>
      </section>


      {/* Section 7 */}
      <section>
        <div className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:grid lg:grid-cols-2">
            <div className=" bg-white">
              <div className="max-w-2xl mx-auto py-24 lg:py-64 lg:max-w-none">
                <div className="lg:pr-16">
                  <h1 className="text-5xl text-black xl:text-6xl">
                    No Cash, No Cards
                  </h1>
                  <h1 className="text-5xl text-blue-500 ">
                    Just Your Kowe Wallet
                  </h1>
                  <p className="text-gray-500 text-base">
                    Replace your card and cash with Kowe Wallet for faster payment online and in-store.
                  </p>
                  <div className="">
                    <div className="my-3 text-center justify-start items-center flex">
                      <button
                        type="button"
                        className='px-2'
                        onClick={() => setMailModal(true)}
                      >
                        <img
                          className="block h-12 w-auto"
                          src="https://ik.imagekit.io/kowe/kowe/Download%20button.png?updatedAt=1698673288492"
                          alt="kowe"
                        />
                      </button>

                      <button
                        type="button"
                        className='px-2'
                        onClick={() => setMailModal(true)}
                      >
                        <img
                          className="block h-12 w-auto"
                          src="https://ik.imagekit.io/kowe/kowe/Download%20button%20(1).png?updatedAt=1698673288122"
                          alt="kowe"
                        />
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full  lg:absolute  right-0 lg:w-1/2 lg:h-full">
              <img
                src="https://ik.imagekit.io/kowe/kowe/Group.png?updatedAt=1698676609167"
                alt="kowe"
                className="w-full h-full object-center object-cover"
              />
            </div>
          </div>
        </div>
      </section>

      <Faqs />

      <Footer />
    </>
  )
}

export default Root