import React from 'react'
import { Disclosure } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

const BlackNav = ({ setMailModal }) => {
  return (
    <div>
      <Disclosure as="nav" className="bg-black">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <Link
                      to="/"
                      className=" text-white inline-flex items-center px-1 pt-1 text-sm font-medium"
                    >
                      <img
                        className="h-8 w-auto"
                        width={10}
                        height={10}
                        src="https://ik.imagekit.io/kowe/kowe/Kowe%20logo%20Type%20-%20White%202.png?updatedAt=1691928603339"
                        alt="kowe"
                      />
                    </Link>

                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    <Link
                      to="/about"
                      className=" text-white inline-flex items-center px-1 pt-1 text-sm font-medium"
                    >
                      About Us
                    </Link>

                    <Link
                      to="/"
                      className=" text-white inline-flex items-center px-1 pt-1 text-sm font-medium"
                    >
                      Blog
                    </Link>

                    <Link
                      to="/"
                      className=" text-white inline-flex items-center px-1 pt-1 text-sm font-medium"
                    >
                      FAQ
                    </Link>

                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  <button
                    type="button"
                    onClick={() => setMailModal(true)}
                    className="inline-flex items-center px-6 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-blue-500  focus:outline-none "
                  >
                    Download Kowe Wallet
                  </button>


                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-500 hover:bg-transparent focus:outline-none ">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6 bg-blue-500 rounded-md text-white" aria-hidden="true" />
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-blue-500">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
                      </svg>
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                <Disclosure.Button
                  as="a"
                  href="/about"
                  className="text-white block pl-6 pr-2 py-2  text-base font-medium"
                >
                  About Us
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/"
                  className="text-white block pl-6 pr-2 py-2  text-base font-medium"
                >
                  Blog
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/"
                  className="text-white block pl-6 pr-2 py-2  text-base font-medium"
                >
                  FAQ
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="text-black block pl-5 pr-2 py-2  text-base font-medium"
                >
                  <div className="my-3 text-center justify-start items-center flex">
                    <button
                      type="button"
                      className=''
                      onClick={() => setMailModal(true)}
                    >
                      <img
                        className="block h-12 w-auto"
                        src="https://ik.imagekit.io/kowe/kowe/Download%20button.png?updatedAt=1698673288492"
                        alt="kowe"
                      />
                    </button>

                    <button
                      type="button"
                      className='px-2'
                      onClick={() => setMailModal(true)}
                    >
                      <img
                        className="block h-12 w-auto"
                        src="https://ik.imagekit.io/kowe/kowe/Download%20button%20(1).png?updatedAt=1698673288122"
                        alt="kowe"
                      />
                    </button>

                  </div>
                </Disclosure.Button>

              </div>

            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default BlackNav