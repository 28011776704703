import React from 'react'
import BlackNav from '../scalables/BlackNav'
import Footer from '../scalables/Footer'
const people = [
  {
    name: 'Oluwatobiloba Henry',
    role: 'Co-founder, Design Lead',
    imageUrl:
      'https://ik.imagekit.io/kowe/kowe/thumb.png?updatedAt=1698686889486',
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/abouthenry',
  },
  {
    name: 'Kehinde Durodola-Tunde',
    role: 'Co-founder, Product Manager',
    imageUrl:
      'https://ik.imagekit.io/kowe/kowe/thumb%20(1).png?updatedAt=1698686889772',
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/kennyking-dt/',
  },
  {
    name: 'Michael Kalango',
    role: 'Co-founder, Mobile Developer',
    imageUrl:
      'https://ik.imagekit.io/kowe/kowe/thumb%20(2).png?updatedAt=1698686888638',
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/michael-kalango-10b99b16a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
  },
  {
    name: 'Toyosi David',
    role: 'Co-founder, Growth & Marketing',
    imageUrl:
      'https://ik.imagekit.io/kowe/kowe/thumb%20(4).png?updatedAt=1698686889499',
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/toyosi-oluwasijuwomi/',
  },
  {
    name: 'Dare Ayanwale, CBAP',
    role: 'Payment Systems Specialist',
    imageUrl:
      'https://ik.imagekit.io/kowe/kowe/thumb%20(5).png?updatedAt=1698686888371',
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/dare-ayanwale-cbap%C2%AE-itil-42698b15/',
  },
  {
    name: 'Femi Obadimu',
    role: 'Backend Developer',
    imageUrl:
      'https://ik.imagekit.io/kowe/kowe/091f240a-9388-4c68-b7ed-d5435db09644.webp?updatedAt=1698687220386',
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/femi-obadimu/',
  },
  {
    name: 'Adebanji Akinsola',
    role: 'Mobile Developer',
    imageUrl:
      'https://ik.imagekit.io/kowe/kowe/thumb%20(6).png?updatedAt=1698686887721',
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/adebanji-akinsola-021564212?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
  },
  {
    name: 'Wegba Ononuro',
    role: 'Product Designer',
    imageUrl:
      'https://ik.imagekit.io/kowe/kowe/thumb%20(7).png?updatedAt=1698686888885',
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/wegba-ononuro-msc-189407188?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
  },

]


const About = ({ mailModal, setMailModal }) => {
  return (
    <div>
      <BlackNav {...{ mailModal, setMailModal }} />

      {/* About Section */}
      <section>
        <div className="section-one bg-black">
          <div className="">
            <div className="px-4 sm:px-6  py-32 lg:px-8">
              <h1 className="text-center text-4xl font-extrabold  sm:text-5xl lg:text-6xl">
                <span className="block text-5xl text-white">We are building convenient payment<br /> for modern, busy lives in Africa </span>
              </h1>
              {/* <p className="my-3 max-w-lg mx-auto text-center text-xl text-black sm:max-w-3xl">
                   <button
                    type="button"
                    className="inline-flex items-center px-6 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-black  focus:outline-none "
                  >
                    Download Now
                  </button>
                  </p> */}
            </div>
          </div>
          <div className="flex items-center justify-center ">
            <div>
              <p className='max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:py-10 lg:px-8'>
                <img
                  className=" items-center w-96 h-96 object-cover"
                  src="https://ik.imagekit.io/kowe/kowe/Illustration.png?updatedAt=1691928023640"
                  alt="Kowe"
                />
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Teams Section */}
      <section>
        <div className="section-2">
          <div className="bg-white">
            <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
              <div className="space-y-12">
                <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                  <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Meet the <span className='text-blue-500'>team</span></h2>

                </div>
                <ul
                  className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl"
                >
                  {people.map((person) => (
                    <li key={person.name}>
                      <div className="space-y-6">
                        <img className="mx-auto h-40 w-40 rounded-full xl:w-50 xl:h-50" src={person.imageUrl} alt="" />
                        <div className="space-y-2">
                          <div className="text-lg leading-6 font-medium space-y-1">
                            <h3>{person.name}</h3>
                            <span className="text-blue-500">{person.role}</span> <span>
                              <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500  px-2 inline-flex text-center justify-center items-center">
                                <span className="sr-only">LinkedIn</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                  <path
                                    fillRule="evenodd"
                                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Featured section */}
      <section
        aria-labelledby="social-impact-heading"
        className="max-w-7xl my-14 mx-auto pt-24 px-4 sm:pt-32 sm:px-6 lg:px-8"
      >
        <div className="relative bg-black rounded-3xl overflow-hidden">
          <div className="absolute inset-0 bg-black">
          </div>
          <div className="relative bg-black py-24 px-6   lg:px-16">
            <div className="relative max-w-3xl mx-auto flex flex-col items-center text-center">
              <div className='flex justify-center items-center flex-col'>
                <div>
                  <h1 className="text-center text-4xl font-bold py-3 sm:text-5xl lg:text-6xl">
                    <span className="block text-6xl text-white">Download the Kowe Wallet App & get started now! </span>
                  </h1>
                </div>
                <div className="my-3 text-center justify-center items-center flex">
                  <button
                    type="button"
                    className='px-2'
                    onClick={() => setMailModal(true)}
                  >
                    <img
                      className="block h-12 w-auto"
                      src="https://ik.imagekit.io/kowe/kowe/Download%20button.png?updatedAt=1698673288492"
                      alt="kowe"
                    />
                  </button>

                  <button
                    type="button"
                    className='px-2'
                    onClick={() => setMailModal(true)}
                  >
                    <img
                      className="block h-12 w-auto"
                      src="https://ik.imagekit.io/kowe/kowe/Download%20button%20(1).png?updatedAt=1698673288122"
                      alt="kowe"
                    />
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <Footer />

    </div>
  )
}

export default About